<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            План
          </template>
          <template v-slot:toolbar>
            <b-button v-b-modal.eduPlanItem-modal>{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <div>
              <div>{{ $t("educationPlan.name") }} : {{ planDetails.name }}</div>
              <div>{{ $t("educationPlan.subjectName") }} : {{ planDetails.subjectName }}</div>
              <div>{{ $t("educationPlan.level") }} : {{ planDetails.level }}</div>
              <div>{{ $t("educationPlan.eduHours") }} : {{ planDetails.eduHours }}</div>
              <div>{{ $t("educationPlan.teacherName") }} : {{ planDetails.teacherName }}</div>
            </div>

            <div>
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <th class="text-left">
                    {{ $t("educationPlanItem.theme") }}
                  </th>
                  <th class="text-left">
                    {{ $t("educationPlanItem.lessonContent") }}
                  </th>
                  <th class="text-left">
                    {{ $t("educationPlanItem.homeWork") }}
                  </th>          
<!--                  <th class="text-left">
                    {{ $t("educationPlanItem.additionalMaterials") }}
                  </th>-->
                  <th class="text-left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in planItems" :key="item.id">
                  <td>{{ item.theme }}</td>
                  <td>{{ item.lessonContent }}</td>
                  <td>{{ item.homeWork }}</td>
<!--                  <td>
                    <button class="btn btn-icon btn-success" @click="addAdditionalMaterial(item.id)">
                      <i class="fa fa-plus"></i>
                    </button>
                    <table v-if="item.additionalMaterials.length > 0" class="table table-bordered">
                      <thead class="thead-light">
                      <tr>
                        <th>Тип</th>
                        <th>Название</th>
                        <th>Значение</th>
                      </tr>
                      </thead>     
                      <tbody>
                      <tr v-for="(additionalMaterial, k) in item.additionalMaterials" :key="k">
                        <td>
                          {{showMaterialType(additionalMaterial.type)}}
                        </td>
                        <td>
                          {{additionalMaterial.name}}
                        </td>
                        <td>
                          <a v-if="additionalMaterial.type === 'link'" :href="additionalMaterial.value" target="_blank">Перейти</a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>-->
                  <td>
                    <b-button
                        @click="editPlanItem(item)"
                        variant="primary"
                    >
                      {{ $t("common.edit") }}
                    </b-button>         
                    <b-button
                        @click="deletePlanItem(item)"
                        variant="danger"
                    >
                      {{ $t("common.delete") }}
                    </b-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


            <div>
              <b-modal id="eduPlanItem-modal" ref="eduPlanItem-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("educationPlanItem.theme") }}
                    </template>
                    <b-form-input
                        v-model="form.theme"
                        :state="eduPlanItemModelState.theme"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ eduPlanItemModelSaveError.theme }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("educationPlanItem.lessonContent") }}
                    </template>
                    <b-form-textarea
                        v-model="form.lessonContent"
                        :state="eduPlanItemModelState.lessonContent"
                    ></b-form-textarea>
                    <div class="invalid-feedback">{{ eduPlanItemModelSaveError.lessonContent }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("educationPlanItem.homeWork") }}
                    </template>
                    <b-form-textarea
                        v-model="form.homeWork"
                        :state="eduPlanItemModelState.homeWork"
                    ></b-form-textarea>
                    <div class="invalid-feedback">{{ eduPlanItemModelSaveError.homeWork }}</div>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>

            <b-modal id="additionalMaterial-modal" ref="additionalMaterial-modal">
              <template v-slot:modal-title>
                {{ $t("common.new") }}
              </template>
              <b-form>
                <b-form-group>
                  <template v-slot:label>
                    {{ $t("educationPlanItem.additionalMaterial.type") }}
                  </template>
                  <b-form-select
                      v-model="additionalMaterialForm.type"
                      :state="additionalMaterialFormState.theme"
                      :options="additionalMaterialTypes"
                  ></b-form-select>
                  <div class="invalid-feedback">{{ additionalMaterialFormSaveError.theme }}</div>
                </b-form-group>
                <b-form-group>
                  <template v-slot:label>
                    {{ $t("educationPlanItem.additionalMaterial.name") }}
                  </template>
                  <b-form-input
                      v-model="additionalMaterialForm.name"
                      :state="additionalMaterialFormState.name"
                  ></b-form-input>
                  <div class="invalid-feedback">{{ additionalMaterialFormSaveError.name }}</div>
                </b-form-group>      
                <b-form-group v-if="additionalMaterialForm.type === 'link'">
                  <template v-slot:label>
                    {{ $t("educationPlanItem.additionalMaterial.value") }}
                  </template>
                  <b-form-input
                      v-model="additionalMaterialForm.value"
                      :state="additionalMaterialFormState.value"
                  ></b-form-input>
                  <div class="invalid-feedback">{{ additionalMaterialFormSaveError.value }}</div>
                </b-form-group>     
                <b-form-group v-if="additionalMaterialForm.type !== 'link'">
                  <template v-slot:label>
                    {{ $t("educationPlanItem.additionalMaterial.file") }}
                  </template>
                  <b-form-input
                      v-model="additionalMaterialForm.file"
                      :state="additionalMaterialFormState.file"
                  ></b-form-input>
                  <div class="invalid-feedback">{{ additionalMaterialFormSaveError.file }}</div>
                </b-form-group>
              </b-form>
              <template v-slot:modal-footer>
                <b-button @click="additionalMaterialSave" variant="primary">{{
                    $t("common.save")
                  }}
                </b-button>
                <b-button @click="addAdditionalMaterialModalClose" variant="danger">{{
                    $t("common.cancel")
                  }}
                </b-button>
              </template>
            </b-modal>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "teacherEduPlanDetail",
  components: {
    KTPortlet
  },
  data() {
    return {
      planId: null,
      /**/
      additionalMaterialModalName : 'additionalMaterial-modal',
      /**/
      planDetails: {
        name: "",
        level: "",
        eduHours: "",
        subjectName: "",
        teacherName: "",
      },
      planItems: [],
      dialog: true,
      form: {
        theme: "",
        educationHours: "",
        homeWork: "",
        lessonType: "",
        competencies: "",
        literature: "",
        zun: "",
        note: "",
        educationPlanId: 0,
      },
      show: true,
      eduPlanItemModelState:{},
      eduPlanItemModelSaveError:{},
      additionalMaterials:{},
      
      additionalMaterialForm:{},
      additionalMaterialFormState:{},
      additionalMaterialFormSaveError:{},
      additionalMaterialTypes: DictionariesService.educationPlanAdditionalMaterialTypes(),
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.planId = this.$route.params.id;
      this.form.educationPlanId = this.$route.params.id;

      this.eduPlanItemModelState = {};
      this.eduPlanItemModelSaveError = {};
      
      ApiService.postSecured("eduPlans/saveItem", this.form)
          .then(function () {
            $this.$refs["eduPlanItem-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.eduPlanItemModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.eduPlanItemModelState[i] = false;
              }
            }
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["eduPlanItem-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("eduPlans/items", {
        params: {planId: this.$route.params.id}
      }).then(({data}) => {
        this.planItems = data;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("eduPlans/find", {
        params: {id: this.$route.params.id}
      }).then(({data}) => {
        this.planDetails = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    editPlanItem(planItem) {
      this.form = planItem;
      this.$refs["eduPlanItem-modal"].show();
    },
    deletePlanItem(planItem) {
      let $this = this;
      if(!confirm("Удалить?")){
        return;
      }
      
      ApiService.postSecured("eduPlans/deleteItem", {id:planItem.id})
          .then(function () {
            $this.reloadData();
          })
          .catch(() => {
            $this.reloadData();
          });
    },
    /*additional materials*/
    addAdditionalMaterial(itemId){
      this.additionalMaterialForm = {
        itemId: itemId
      };
      this.additionalMaterialFormState = {};
      this.additionalMaterialFormSaveError = {};

      this.$refs[this.additionalMaterialModalName].show();
    },
    additionalMaterialSave(){
      let $this = this;
      ApiService.postSecured("eduPlans/saveEducationPlanAdditionalMaterial", this.additionalMaterialForm)
          .then(function () {
            $this.$refs[$this.additionalMaterialModalName].hide();
            
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.additionalMaterialFormSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.additionalMaterialFormState[i] = false;
              }
            }
          });
    },
    addAdditionalMaterialModalClose(){
      this.additionalMaterialForm = {};
      this.additionalMaterialFormState = {};
      this.additionalMaterialFormSaveError = {};
      
      this.$refs[this.additionalMaterialModalName].hide();
    },
    
    showMaterialType(type){
      for (let i in this.additionalMaterialTypes ){
        let typV = this.additionalMaterialTypes[i];
        
        if(typV['value'] === type){
          return typV['text'];
        }
      }
      
      return "";
    }
  }
};
</script>
